import React from "react"
import { StyleSheet, css } from "aphrodite"

const GridTwo = ({ left, right }) => {
  return (
    <div className={css(styles.grid2) + " is-size-5"}>
      <div
        dangerouslySetInnerHTML={{ __html: left }}
        className="is-relative"
        data-sal="slide-right"
        data-sal-duration="800"
        data-sal-delay="300"
        data-sal-easing="ease"
      ></div>
      <div
        dangerouslySetInnerHTML={{ __html: right }}
        className="is-relative"
        data-sal="slide-right"
        data-sal-duration="800"
        data-sal-delay="300"
        data-sal-easing="ease"
      ></div>
    </div>
  )
}

const styles = StyleSheet.create({
  grid2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "8rem",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gridGap: "2rem",
    },
  },
})
export default GridTwo
