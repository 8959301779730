import React from "react"
import { StyleSheet, css } from "aphrodite"

const Headline = ({ title, excerpt }) => {
  return (
    <React.Fragment>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <section
        data-sal="fade"
        data-sal-duration="500"
        data-sal-delay="0"
        data-sal-easing="ease"
        className={css(styles.wrapper) + " section is-medium"}
      >
        <div className="container">
          <div className={css(styles.headline) + " has-text-white"}>
            <h1
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="is-size-1"
            >
              {title}
            </h1>
            <p
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="is-size-4"
            >
              {excerpt}
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "#0067F3",
    marginTop: "0",
  },
  headline: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
    },
  },
})
export default Headline
