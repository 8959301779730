import React from "react"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"
import Layout from "../components/layout"

const AboutUs = () => {
  const [popup, setPopup] = React.useState(["", ""])

  return (
    <Layout>
      <div className={"modal " + popup[0]}>
        <div
          className="modal-background"
          onClick={e => setPopup(["", ""])}
        ></div>
        <div
          className="modal-content"
          style={{ backgroundColor: "white", padding: "2.5rem" }}
          dangerouslySetInnerHTML={{ __html: popup[1] }}
        ></div>
        <button
          className="modal-close is-large"
          onClick={e => setPopup(["", ""])}
          aria-label="close"
        ></button>
      </div>

      <Headline
        title="History"
        excerpt="Started as RMGR (Ramditta Mal Ganga Ram) - a multi-functional group in Gojra 9 decades ago in the year 1929, Alliance World came into inception in 2003 to further diversify its portfolio into pharmaceuticals, FMCG, agriculture, engineering tech, farming & marketing."
      />

      <section className="section">
        <div className="container">
          <h3 className="is-size-3">About</h3>
          <br />
          <GridTwo
            left="The world of Alliance World Group is one without boundaries – growing, changing and challenging. It specializes in supply chain, contract manufacturing and need based services by not only meeting the current needs of the global customer base but also developing exciting new solutions that deliver additional value to the customers in future.<br/><br/> As one of India’s most geographically diversified group, Alliance World operates world-class manufacturing facilities at Himachal Pradesh, Punjab, Haryana, Assam, Maharashtra & Chandigarh."
            right="Alliance World Group has played a pioneering role in industrial practices in India and has embarked on some path-breaking initiatives and have made a global impact. Driven by a culture of performance, the Alliance World Group is committed to aspiration targets, safety and social responsibility, continuous improvement, openness & transparency."
          />
        </div>
      </section>

      <section className="section" id="vision">
        <div className="container">
          <GridTwo
            left="<span class='dots'></span><hr /><h3 class='is-size-3 has-text-primary'>Vision</h3><br />
            <span class='is-size-4'>To be the first choice in contract manufacturing and supply chain service in India.</span>"
            right="<span class='dots'></span><hr /><h3 class='is-size-3 has-text-primary'>Mission</h3><br />
            <span class='is-size-4'>To attain market leadership by meeting global standards, building long term relationships with our stakeholders and providing quality services to ensure customer satisfaction.</span>"
          />
        </div>
      </section>

      <section
        className="section has-background-light is-large has-background-image"
        id="quality"
        style={{ backgroundImage: "url(./images/slider/3.jpg)" }}
      >
        <div className="container">
          <h3
            className="is-size-3 has-text-primary"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Quality Policy
          </h3>
          <br />
          <GridTwo
            left="<span class='has-text-primary'>To ensure business growth through customer satisfaction and its continual enhancement by providing quality services to meet our client's external and internal needs.</span>"
            right="<span class='has-text-primary'>We strive to achieve this by understanding the customer’s requirements thoroughly and implementing appropriate and continually improving Quality Management Systems.</span>"
          />
        </div>
      </section>

      <section
        data-sal="fade"
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="ease"
        className="section is-medium has-background-primary has-text-white"
      >
        <div className="container" id="companyoverview">
          <h2
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="is-size-2 has-text-weight-bold  has-text-centered"
          >
            Company Overview
          </h2>
          <br />
          <br />
          <br />
          <div className="columns is-variable is-multiline is-8">
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="200"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              {" "}
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of years of experience since its inception in 1929
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 90 Yrs</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              {" "}
              <br /> <br />
              <p className="is-size-4 has-text-weight-light">
                Annual turnover as per 2018-2019 financial year
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> $170 M</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="column is-4"
            >
              {" "}
              <br /> <br />
              <p className="is-size-4 has-text-weight-light">
                Total no. of group employees as in the year 2018
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 1.6K+</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="200"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of multi-national companies we have partnered with
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 10+ MNC</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              className="column is-4 mob-margin-bottom"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of manufacturing plants spread across India
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 7+</h2>
            </div>
            <div
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="column is-4"
            >
              <br />
              <br />
              <p className="is-size-4 has-text-weight-light">
                No. of product lines owned by Alliance Group
              </p>
              <br />
              <h2 className="is-size-2 has-text-weight-bold"> 6+</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="leadership">
        <div className="container">
          <h3 className="is-size-3" style={{ marginBottom: "1rem" }}>
            Our Team
          </h3>
          <h3 className="is-size-5">Promoters</h3>
          <br />
          <div className="columns is-variable is-8">
            <div
              className="column is-4"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <img src={"/team/opnagpal.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                O.P. Nagpal
                <br />
                <small>Emeritus, Alliance World</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
              <br />
              <p className="is-size-6"></p>
            </div>
            <div
              className="column is-4"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                    Anil Nagpal
                    <br />
                    <small>Partner, Alliance World</small>
                  </h4>
                  <br />
                  <p class="is-size-6">
                    He has an experience of more than 30 years in the business of
                    Supply Chain, Logistics and Manufacturing of Pharmaceuticals &
                    FMCG goods. He has implemented green field projects for ICI plc
                    UK, Johnson & Johnson & Unilever – Passion to venture into new
                    projects to diversify and accept technology as tools of change
                    has taken the group turnover to Rs. 400 million.
                  </p>
                  `,
                ])
              }
            >
              <img src={"/team/bod3.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Anil Nagpal
                <br />
                <small>Partner, Alliance World</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-4"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Harish Agarwal
                <br />
                <small>Partner, Alliance World</small>
              </h4>
              <br />
              <p class="is-size-6">
                He is the Chairman CII North and besides Alliance India is the
                founder promoter of Asian Concrete and Cements Pvt Ltd having
                over 30 years of experience in project execution and management.
                His vast experience allows him to manage construction,
                manufacturing, and logistics with expertise in financial
                administration
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/bod2.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Harish Agarwal
                <br />
                <small>Partner, Alliance World</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
          </div>

          <br />
          <hr
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          />
          <h3 className="is-size-5">Board Members</h3>
          <br />
          <div
            className="columns is-variable is-8 is-mobile  is-multiline"
            style={{ alignItems: "flex-end" }}
          >
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Satish Loomba
                <br />
                <small>Director, Alliance Agritech</small>
              </h4>
              <br />
              <p>
                Coming from a business family which had pre partition interests
                in agricultural commodities, finance, manufacturing and civil
                construction, Mr Satish Loomba turned a career bureaucrat ( IAAS
                ) on the basis of the 1981 civil services examination. He
                superannuated from service after a long and distinguished career
                with the Government of India, in the course of which he held
                several top positions across various sectors including Finance,
                Power, Education etc. ; overseeing and interfacing with several
                state, interstate, national and international administrations.
                <br />
                <br />
                He is an alumnus of SRCC and also a Chartered Secretary.
                Passionate about yoga, poetry, music he is also an active
                speaker on environment and climate change.
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/satish.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Satish Loomba
                <br />
                <small>Director, Alliance Agritech</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">Karan Agarwal <br/> <small>Director, Duraton Cement</small></h4>
              <br />
              <p>
                MR KARAN AGARWAL is a Chemical Engineer from Thapar Institute of
                Engineering and Technology, Patiala, and MBA from S. P. Jain
                Institute of Management and Research, Mumbai. He is also a
                Director on the Board of Asian Fine Cements Pvt. Ltd. He
                foresees Asian Cements as a leading company in the North Region
                based on the growth momentum achieved by the company in a brief
                period of three years. His business management acumen, combined
                with his strong commitment to quality and a customer-centric
                approach has helped the company to attain the highest standards
                in the cement industry at an accelerated pace.
                <br />
                <br />
                He evinces a keen interest in the corporate affairs of the
                company and plays a pivotal part in all the key policy-making
                decisions. A keen observer with an analytical mind, he is
                spearheading the branding, communications, and marketing
                activities which he has identified as a thrust area for the
                company.
              </p>

                  `,
                ])
              }
            >
              <img src={"/team/karan.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Karan Agarwal <br /> <small>Director, Duraton Cement</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                Dr. S.D. Rampal
                <br /> <small>Director, Imperial Marketing</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Sanjiv Narain <br /> <small>President, Alliance World</small>
              </h4>
              <br />
              <p>
                Sanjiv - an alumnus of IIT Kanpur and IIM Kolkata has an
                experience of more than 30 years in Automobiles, Healthcare &
                Medical Devices. <br />
                <br /> He was previously Director of Operations for J&J for the
                Asia Pacific, Europe, Middle East & Africa Regions He has
                implemented green field projects and carried out Asset
                reconfiguration Strategies for J&J
              </p>

                  `,
                ])
              }
            >
              <img src={"/team/sanjiv.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Sanjiv Narain <br /> <small>President, Alliance World</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
          </div>

          <hr
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          />
          <h3 className="is-size-5">Professional Team</h3>
          <br />
          <div
            className="columns is-variable is-8 is-mobile is-multiline"
            style={{ alignItems: "flex-end" }}
          >
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Dr. Malkiat Singh
                <br /> <small>Director, Alliance Formulations</small>
              </h4>
              <br />
              <p>
                Dr Malkiat Singh has a rich experience of 36 years in clinical
                microbiology operations specially related to control of Hospital
                acquired infections and more than 25 years of experience in
                teaching Medical Microbiology at PGIMER Chandigarh
                <br />
                <br />
                His area of interests and areas of work are laboratory
                investigations for infectious diseases and Hospital Infection
                control . Sterilization and disinfection and Biosafety in
                Microbiology laboratories
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/malkiat.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Dr. Malkiat Singh
                <br /> <small>Director, Alliance Formulations</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 className="is-size-5 has-text-weight-bold">
                Rajesh Kumar <br /> <small>Executive Director, Medical Instruments</small>
              </h4>
              <br />
              <p>
                Rajesh Kumar has a rich experience of 30 years in Manufacturing,
                Marketing and Sales of Medical Devices, and Engineering
                products.
                <br />
                <br />A Masters of Science alumni from Panjab University ,
                Rajesh has been instrumental in project planning , and
                commissioning of manufacturing sites for Medical Devices and
                FMCG products . He has established Quality Management Systems
                leading to certifications in ISO 9001 , ISO 13485 . Rajesh has a
                acumen in statutory requirements like FDA,
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/rajesh.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Rajesh Kumar <br />{" "}
                <small>Exec. Director, Medical Instruments</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Anis A Quraishi
                <br/>
                <small>Director, Alliance India</small>
              </h4>
              <br />
              <p>
                Anis - an alumnus of NIT Durgapur and has an experience of more
                than 20 years with around 18 years of experience in FMCG,
                Plastic Products and Engineering.
                <br />
                <br />
                He has been the Director of Operations for Alliance FMCG
                Division and previously heading manufacturing for a Leading
                Plastics Manufacturing Group
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/anis.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Anis A Quraishi
                <br />
                <small>Director, Alliance India</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
              style={{ cursor: "pointer" }}
              onClick={e =>
                setPopup([
                  "is-active",
                  `
                  <h4 class="is-size-5 has-text-weight-bold">
                Arvind Raman
                <br />
                <small>Plant Head, Duraton Cement</small>
              </h4>
              <br />
              <p>
                Arvind Raman has a rich experience of 26 years in Manufacturing
                of Cement products, Automobile electrical and FMCG products
                <br />
                <br />
                Arvind holds a B.E . From Bangalore University . Prior to
                joining the Alliance World Group he has been the General Manager
                Operations with Phoenix Lamps and ITC .
                <br />
                <br />
                Arvind has a keen interest in application of cutting edge
                technological solutions for Industrial manufacturing . He has
                also a passion for Human Resources development and Mentoring .
              </p>
                  `,
                ])
              }
            >
              <img src={"/team/arvind.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Arvind Raman
                <br />
                <small>Plant Head, Duraton Cement</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                S.K. Rastogi <br />
                <small>Director Operations, Agritech</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h4 className="is-size-5 has-text-weight-bold">
                Ajay Chadha <br />
                <small>Director, Sales, Alliance World</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
            <div
              className="column is-6-mobile is-3-tablet"
              data-sal="slide-right"
              data-sal-duration="800"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <img src={"/team/garima.jpg"} alt="" />
              <br />
              <br />
              <h4 className="is-size-5 has-text-weight-bold">
                Garima Nagpal Ahuja
                <br />
                <small>Director, Marketing</small>
                <br />
                <img
                  style={{ marginTop: "0.5rem" }}
                  src="https://img.icons8.com/ios/25/0068f3/long-arrow-right.png"
                />
              </h4>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default AboutUs
